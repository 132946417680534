module.exports = {
    development : false,	
    wrapper: {
      url: "https://eos.eoxvantage.com:9080/"
  },
ui: {
      url: "https://eos.eoxvantage.com/"
  },
  api: {
      url: "https://eos.eoxvantage.com:9080/"
 },
 desktop: {
        settings: {
            font: "Roboto",
            theme: "Vision"
        }
    },
  
  keycloakLogin: true,
  keycloak: {
    keycloakRedirectUrl:"https://eos.eoxvantage.com/",
    keycloakUrl: "https://secure.eoxvantage.com/",
    keycloakRealm: "master",
    keycloakClientId: "admin-cli",
  }

}
