import { ServiceProvider } from "@osjs/common";
import LocalStorageAdapter from "./localStorageAdapter.js";
import Swal from "sweetalert2";
export class MessageServiceProvider extends ServiceProvider {
  constructor(core, options = {}) {
    super(core, options || {});
    this.core = core;
  }

  providers() {
    return ["oxzion/messageDialog"];
  }

  init() {
    this.core.instance("oxzion/messageDialog", () => ({
      show: this.show,
    }));
  }

  async show(
    title,
    message,
    confirmButtuonText,
    showCancelButtonText,
    htmlContent = undefined,
    customClass = undefined,
    preConfirm = undefined,
    imageUrl = null,
    icon = undefined,
    iconColor = undefined,
    didOpen = undefined,
    target = undefined
  ) {
    return await Swal.fire({
      title: title,
      text: message,
      imageUrl: imageUrl ? imageUrl : undefined,
      html: htmlContent ? htmlContent : undefined,
      customClass: customClass ? customClass : undefined,
      preConfirm: preConfirm ? preConfirm : undefined,
      didOpen: didOpen ? didOpen : undefined,
      imageWidth: 75,
      imageHeight: 75,
      confirmButtonText: confirmButtuonText,
      confirmButtonColor: "#d33",
      showCancelButton: showCancelButtonText,
      cancelButtonColor: "#3085d6",
      icon: icon ? icon : undefined,
      iconColor: iconColor ? iconColor : undefined,
      allowOutsideClick: false,
      allowEscapeKey: false,
      target: target ? target : null
    }).then((result) => {
      if(preConfirm) {
          return result;
      }
      if (result.hasOwnProperty("dismiss")) {
        return false;
      } else {
        return true;
      }
    });
  }
}
