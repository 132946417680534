import { ServiceProvider } from "@osjs/common";
import LocalStorageAdapter from "./localStorageAdapter.js";

export class UserSessionServiceProvider extends ServiceProvider {
  constructor(core, options = {}) {
    super(core, options || {});
    this.core = core;
    core.on("osjs/core:logged-in", () => this.get());
  }

  providers() {
    return ["oxzion/usersession"];
  }

  init() {
    this.core.instance("oxzion/usersession", () => ({
      get: () => this.get(),
      set: () => this.setUserSession(),
      remove: () => this.removeUserSession()
    }));
    this.lsHelper = new LocalStorageAdapter();
  }

  async get() {
    let helper = this.core.make("oxzion/restClient");
    let session = await helper.request("v1", "/user/me/getsession", {}, "get");
    var queryString = window.location.search.substr(1);
    if (queryString && session) {
      var queryObj = queryString
        .split("&")
        .reduce(function (prev, curr, i, arr) {
          var p = curr.split("=");
          prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
          return prev;
        }, {});
      var appName = queryObj.app;
      if (appName) {
        const array = session?.data?.filter((each) => each.name !== appName);
        session = {
          data: array ?? [],
          status: "success",
        };
      }
    }
    this.lsHelper.supported();
    let userSession = this.lsHelper.get("UserInfo");
    this.core
      .make("osjs/settings")
      .set(
        "osjs/session",
        null,
        session["data"].filter(
          (i) => !userSession?.key?.blackListedApps?.hasOwnProperty(i.name)
        )
      )
      .save();
    if (userSession?.key?.cleared_browser_cache == 1) {
      // clearCacheData();
      localStorage.clear();
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      this.lsHelper.purge("UserInfo");
      this.lsHelper.purge("Metadata");

      // this.core.make("oxzion/profile").set();
      // this.core.make("oxzion/profile").getMetadata();

      localStorage.setItem("version", "New");
      window.location.reload(true);

      // Update the user cache value to 0
      await helper.request(
        "v1",
        "/user/" + userSession?.key?.id,
        JSON.stringify({
          cleared_browser_cache: 0,
        }),
        "PUT"
      );
    }
  }

  async setUserSession() {
    let session = this.core.make("osjs/settings").get("osjs/session");
    session = Object.keys(session).map((key) => {
      session[key].args = {};
      return session[key];
    });
    let helper = this.core.make("oxzion/restClient");
    let updatesession = await helper.request(
      "v1",
      "/user/me/updatesession",
      { data: JSON.stringify(session) },
      "post"
    );
  }
  
  async removeUserSession() {
    let session = this.core.make("osjs/settings").get("osjs/session");
    session = Object.keys(session).map((key) => {
      session[key].args = {};
      return session[key];
    });
    let helper = this.core.make("oxzion/restClient");
    let removesession = await helper.request(
      "v1",
      "/user/me/removesession",
      { data: JSON.stringify(session) },
      "post"
    );
  }
}
