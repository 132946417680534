import { Login as defaultLogin } from "../../osjs-client/index.js";
import "../assets/scss/login.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import oxLogo from "../assets/images/eox.png";
import wallpaper from "../assets/images/wallpaper.png";
import AnnouncementIcon from "../assets/images/icon_white.svg";
import ForgotPassword from "./ForgotPassword.js";
import ResetPasswordPage from "./ResetPasswordPage.js";
import Slider from "./Slider.js";
import LocalStorageAdapter from "../adapters/localStorageAdapter.js";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";

export default class LoginContainer extends defaultLogin {
  // check 54802 commit and replace it will work
  constructor(core, options = {}) {
    super(core, options || {});
    this.core = core;
    this.baseurl = this.core.config("ui.url");
    this.viewurl = this.core.config("wrapper.url");
    const domain = new URL(this.core.config("api.url"));
    this.keycloakUrl = this.core.config("keycloak.keycloakUrl");
    this.keycloakRealm = domain.hostname;
    this.keycloakClientId = domain.hostname + "-cli";
    this.keycloak = new Keycloak({
      url: this.keycloakUrl + "auth",
      realm: this.keycloakRealm,
      clientId: this.keycloakClientId,
    });
    this.onLoad();
  }

  async onLoad() {
    if (this.core.config("keycloakLogin")) {
      const redirect = window.location.href.split(/#state|&code|&session_state/).length === 4;
      if (!redirect) return;
      const formData = new FormData();
      formData.append("grant_type", "authorization_code");
      formData.append("client_id", this.keycloakClientId);
      formData.append("redirect_uri", window.location.href.split("#state=")[0]);
      formData.append("code", window.location.href.split("&code=")[1]);

      for (var pair of formData.entries()) {
        console.log(pair[0] + " - " + pair[1]);
      }
      const response = await (
        await fetch(this.keycloakUrl + "auth/realms/" + this.keycloakRealm + "/protocol/openid-connect/token", {
          body: new URLSearchParams(formData),
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
      ).json();
      console.log("response", response); // redirect uri in the browser url is the issue, if u rmv fileid and app , it will work
      if (response.access_token) {
        const keycloakValidateForm = new FormData();
        keycloakValidateForm.append("access_token", response.access_token);
        let keycloakValidateResponse = await (
          await fetch(this.viewurl + "auth/external/keycloak", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(keycloakValidateForm),
          })
        ).json();
        let res = [
          {
            result: keycloakValidateResponse.data,
            ref_token: response.refresh_token,
          },
        ];
        this.emit("login:post", res);

        //window.location.replace(this.baseurl);
        window.location.replace(window.location.href.split("#state=")[0]);
      }
    }
  }

  render() {
    var node = document.createElement("div");
    node.className = "reactLoginPage";
    document.body.appendChild(node);

    // let authToken = this.core.make("oxzion/profile").getAuth();
    let location = window.location.href;

    if (location.includes("#forgotpassword")) {
      ReactDOM.createRoot(document.getElementsByClassName("reactLoginPage")[0]).render(<ForgotPassword core={this.core} showLoginPage={() => window.location.replace(this.baseurl)} />);
    } else if (location.includes("resetpassword")) {
      ReactDOM.createRoot(document.getElementsByClassName("reactLoginPage")[0]).render(
        <ResetPasswordPage core={this.core} resetPasswordToken={new URLSearchParams(window.location.search).get("resetpassword")} showLoginPage={() => window.location.replace(this.baseurl)} />,
      );
    } else if (this.core.config("keycloakLogin")) {
      ReactDOM.createRoot(document.getElementsByClassName("reactLoginPage")[0]).render(KcLogin(this.keycloak));
    } else {
      const b = ReactDOM.createRoot(document.getElementsByClassName("reactLoginPage")[0]).render(<Login core={this.core} thisObj={this} />);
      this.on("login:stop", () => {
        let AuthToken = this.core.make("oxzion/profile").getAuth();
        if (AuthToken) {
          ReactDOM.createRoot(document.getElementsByClassName("reactLoginPage")[0]).unmount();
        }
      });
    }
  }
}

const KcLogin = (keycloak) => {
  const redirect = window.location.href.split(/#state|&code|&session_state/).length === 4;

  return !redirect ? (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: "login-required", checkLoginIframe: false }} // onLoad: "login-required"
      onEvent={(event, error) => {
        console.log("onKeycloakEvent", event, error);
      }}
    ></ReactKeycloakProvider>
  ) : null;
};

//OSJS-LOGIN
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.core = this.props.core;
    this.state = {
      showPage: "login",
      error: false,
      username: "",
      password: "",
      resetPasswordToken: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.loginAction = this.loginAction.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
  }

  componentDidMount() {
    var queryObj = window.location.search.substr(1);
    queryObj = queryObj.split("&").reduce(function (prev, curr) {
      var p = curr.split("=");
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
      return prev;
    }, {});
    queryObj.resetpassword
      ? this.setState({
          showPage: "resetpassword",
          resetPasswordToken: queryObj.resetpassword,
        })
      : null;
  }

  errorMessage(e) {
    this.setState({ error: e.detail });
  }

  handleChange(e) {
    let target = e.target;
    this.setState(
      {
        [target.name]: target.value,
      },
      () => this.state.username.length >= 0 && this.state.password.length >= 0 && this.setState({ error: undefined }),
    );
  }

  loginAction(e) {
    e.preventDefault();
    var loginDetails = {
      username: this.state.username,
      password: this.state.password,
    };

    if (loginDetails.username == "" || loginDetails.password == "") {
      this.setState({ error: "The username or password cannot be empty" });
    } else {
      this.setState({ error: false });
      this.props.thisObj.emit("login:post", loginDetails);
      this.props.thisObj.on("login:error", (error) => {
        this.setState({ error: "The username or password you entered is incorrect" });
      });
    }
  }

  render() {
    if (this.state.showPage == "resetpassword") {
      return <ResetPasswordPage core={this.core} resetPasswordToken={this.state.resetPasswordToken} showLoginPage={() => this.setState({ showPage: "login" })} />;
    } else if (this.state.showPage == "forgotpassword") {
      return <ForgotPassword core={this.core} showLoginPage={() => this.setState({ showPage: "login", error: false })} />;
    } else {
      return (
        <main id="login-container " className="loginContainer row lighten-3 ">
          <img
            src={wallpaper}
            alt="EOX Vantage"
            style={{
              position: "absolute",
              width: "100vw",
              height: "135vh",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          />
          <div
            className="loginSlider col-8"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Slider core={this.core} />
          </div>
          <div id="ox-login-form" className="col-4">
            <div className="form-wrapper__inner" id="loginPage" style={{ display: "block" }}>
              <form className="ox-form " method="post" onSubmit={this.loginAction}>
                <div id="ox-img" className="ox-imgDiv">
                  <img id="ox-logo" className="ox-img" src={oxLogo} />
                </div>
                <div className="floating-label">
                  <input type="text" name="username" className="validate" id="username" placeholder="Username" value={this.state.username} onChange={this.handleChange} />
                  <label htmlFor="username">Username</label>
                </div>
                <div className="floating-label">
                  <input type="password" name="password" className="validate" id="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
                  <label htmlFor="password">Password</label>
                </div>
                <div className="form-signin__footer">
                  <button type="submit" value="login" className="btn waves-effect waves-light">
                    Login
                  </button>
                  <a onClick={() => this.setState({ showPage: "forgotpassword", error: null })}>Forgot your password?</a>
                </div>
              </form>
              <div className="osjs-login-error text-center" style={{ display: this.state.error ? "block" : "none" }}>
                <span>{this.state.error}</span>
              </div>
            </div>
            <footer className="footer-links">
              <a href="https://www.eoxvantage.com">About Us</a> &nbsp;&nbsp; | &nbsp;&nbsp;
              <a href="./privacy-policy" target="_blank">
                Privacy Policy
              </a>
              <div className="coppyRight">Copyright © {new Date().getFullYear()} EOX Vantage. All rights reserved.</div>
            </footer>
          </div>
        </main>
      );
    }
  }
}
