import { h } from "hyperapp";
import PanelItem from "../panel-item";
export default class NotificationCenterPanel extends PanelItem {
  init() {
    if (this.inited) {
      return;
    }
    this.helper = this.core.make("oxzion/restClient");
    this.splash = this.core.make("oxzion/splash");
    this.osjsApps = this.core.make("osjs/packages").getPackages();
    this.NotificationCenterActions = super.init(
      {
        selectedType: "assignments",
        taskList: [],
        rygFocus: "",
        rygCount: {
          red: 0,
          yellow: 0,
          green: 0,
          blue: 0,
        },
        loading: true,
        showDropdown: false,
        isSnoozeModalOpen: false,
        selectedTask: {},
        snoozeModalFlag: "snooze",
        isCommentsModalOpen: false,
        commentsModalFlag: "comments",
        userId: "",
        totalCount: 0,
      },
      {
        updateState: this.updateState,
        onTaskActionClick: this.onTaskActionClick,
        launchNotificationApp: this.launchNotificationApp,
        focusNotificationPanel: this.focusNotificationPanel,
      },
    );
  }

  fetchTasks(selectedType, rygStatus) {
    setTimeout(() => {
      this.splash.show(document.getElementsByClassName("notificationCenter-panel")[0], "Loading...");
    }, 100);
    let filter = {};
    let method = "get";
    if (rygStatus) {
      filter.ryg_status = rygStatus;
      method = "post";
    }
    this.helper
      .request("v1", `/user/getusernotifications?type=${selectedType}&take=5&skip=0`, filter, method)
      .then((response) => {
        if (response && response?.data?.data) {
          let taskList = [];
          let appListwithTasks = response?.data?.data?.filter((each) => each?.count !== 0);
          appListwithTasks?.map((eachApp) => {
            eachApp?.data?.map((eachTask) => {
              let app = this.osjsApps?.filter((i) => i.appId == eachApp.appUuid);
              eachTask.app_title = app.length > 0 ? app[0]?.title?.en_EN : eachApp.appName;
              eachTask.name = eachTask.task_name?.replace(/"/g, "") ?? "NA";
              taskList.push(eachTask);
            });
          });
          let totalCount = response?.data?.ryg_count?.red + response?.data?.ryg_count?.yellow;
          this.NotificationCenterActions.updateState({
            rygFocus: "",
            totalCount: totalCount ?? 0,
            rygCount: response?.data?.ryg_count ?? {
              red: 0,
              yellow: 0,
              green: 0,
              blue: 0,
            },
            userId: response?.data?.id ?? "",
            isSnoozeModalOpen: false,
            isCommentsModalOpen: false,
            loading: false,
            taskList,
          });
          this.splash.destroy();
        } else {
          this.NotificationCenterActions.updateState({
            rygFocus: "",
            totalCount: 0,
            rygCount: {
              red: 0,
              yellow: 0,
              green: 0,
              blue: 0,
            },
            userId: "",
            isSnoozeModalOpen: false,
            isCommentsModalOpen: false,
            loading: false,
            taskList: [],
          });
          this.splash.destroy();
        }
      })
      .catch((error) => {
        this.splash.destroy();
      });
  }

  updateState = (data) => data;

  onTaskActionClick =
    ({ action, task, actions }) =>
    async (state) => {
      const slide = document.getElementsByClassName("notificationCenter-panel");
      switch (action) {
        case "edit":
          slide[0]?.classList.remove("panel-active");
          let appName = this.osjsApps?.map((i) => i.name).filter((i) => i == task.appName);
          appName
            ? this.core.run(
                appName[0],
                {
                  fileId: task.uuid,
                  taskInfo: task,
                  edit: 1,
                },
                { restart: true },
              )
            : null;
          break;
        case "unsnooze":
          this.NotificationCenterActions.updateState({
            isSnoozeModalOpen: true,
            selectedTask: task,
            snoozeModalFlag: "unsnooze",
            isCommentsModalOpen: false,
          });
          break;
        case "snooze":
          this.NotificationCenterActions.updateState({
            isSnoozeModalOpen: true,
            selectedTask: task,
            snoozeModalFlag: "snooze",
            isCommentsModalOpen: false,
          });
          break;
        case "comments":
          this.NotificationCenterActions.updateState({
            isCommentsModalOpen: true,
            selectedTask: task,
            commentsModalFlag: "comments",
            isSnoozeModalOpen: false,
          });
          break;
        default:
          break;
      }
    };

  gotoTaskViewPage = (task) => {
    let appName = this.osjsApps?.map((i) => i.name).filter((i) => i == task.appName);
    appName.length !== 0
      ? this.core.run(
          appName[0],
          {
            fileId: task.uuid,
            taskInfo: task,
          },
          { restart: true },
        )
      : null;
  };

  launchNotificationApp = (state, actions) => {
    this.core.run(
      "Priorities",
      {
        ...state,
      },
      { restart: true },
    );
    this.NotificationCenterActions.updateState({
      isSnoozeModalOpen: false,
    });
  };

  loadModalComponent = (state, actions, helper, actionFlag) => {
    if (actionFlag === "snooze") {
      const domNode = document.getElementById("snooze-modal");
      import("./snoozeModal").then((item) => {
        var React = oxziongui.React;
        oxziongui.ReactDOM.createRoot(domnode).render(
          <item.default
            userId={state.userId}
            flag={state.snoozeModalFlag}
            helper={helper}
            selectedTask={state.selectedTask}
            updateSnoozeModalFlag={(flag) =>
              actions.updateState({
                snoozeModalFlag: flag,
              })
            }
            launchNotificationApp={() => actions.launchNotificationApp(state, actions)}
            closeModal={() =>
              actions.updateState({
                isSnoozeModalOpen: false,
              })
            }
          />,
        );
      });
    } else if (actionFlag === "comments") {
      const domNode = document.getElementById("comments-modal");
      import("./commentsModal").then((item) => {
        var React = oxziongui.React;
        oxziongui.ReactDOM.createRoot(domNode).render(
          <item.default
            flag={state.commentsModalFlag}
            helper={helper}
            selectedTask={state.selectedTask}
            updateCommentsModalFlag={(flag) =>
              actions.updateState({
                commentsModalFlag: flag,
              })
            }
            closeModal={() =>
              actions.updateState({
                isCommentsModalOpen: false,
              })
            }
          />,
        );
      });
    }
  };

  focusNotificationPanel = () => {
    let allOsjsPanelItems = document.getElementsByClassName("osjs-panel-item");
    var arr = [].slice.call(allOsjsPanelItems);
    let notificationElement = arr?.filter((item) => item?.dataset?.name === "notificationCenter");
    let globalSearchElement = arr?.filter((item) => item?.dataset?.name === "globalSearch");
    if (notificationElement?.length > 0) {
      notificationElement[0].style.zIndex = 2;
    }
    if (globalSearchElement?.length > 0) {
      globalSearchElement[0].style.zIndex = 1;
    }
  };

  showNotificationPanel = (event, actions) => {
    const slide = document.getElementsByClassName("notificationCenter-panel");
    event.stopPropagation();
    slide[0]?.classList.toggle("panel-active");
    actions.focusNotificationPanel();
    actions.updateState({
      selectedType: "assignments",
      isCommentsModalOpen: false,
      showDropdown: false,
      isSnoozeModalOpen: false,
    });
    if (slide[0]?.classList.contains("panel-active")) this.fetchTasks("assignments");
  };

  render(state, actions) {
    const slide = document.getElementsByClassName("notificationCenter-panel");

    if (slide[0]?.classList.contains("panel-active")) {
      document.body.onclick = function (e) {
        slide[0]?.classList.remove("panel-active");
      };
    }

    var action =
      state.selectedType === "snoozed"
        ? {
            name: "unsnooze",
            icon: "fa-regular fa-hourglass-clock",
          }
        : {
            name: "snooze",
            icon: "fa-solid fa-alarm-snooze",
          };

    let taskActions = [
      {
        name: "edit",
        icon: "fa-solid fa-pencil",
      },
      // action,
      {
        name: "comments",
        icon: "fa-regular fa-comments",
      },
    ];

    const dropdownOptions = [
      {
        label: "My Follow Up",
        value: "followups",
        icon: "fa-solid fa-turn-up",
      },
      {
        label: "My Priorities",
        value: "assignments",
        icon: "fa-solid fa-light-emergency-on",
      },
      // {
      //   label: "My Snoozed Items",
      //   value: "snoozed",
      //   icon: "fa-solid fa-hourglass-clock",
      // },
    ];

    let rygComponent = h(
      "div",
      {
        className: "rygToggle-container",
      },
      [
        h(
          "div",
          {
            title: `${state.rygCount.red}`,
            className: "rygToggle-r" + (state.rygFocus == "RED" ? " r-focus" : ""),
            onclick: () => {
              actions.updateState({
                rygFocus: "RED",
              }),
                this.fetchTasks(state.selectedType, "RED");
            },
          },
          state.rygCount.red > 100 ? "99+" : state.rygCount.red,
        ),
        h(
          "div",
          {
            title: `${state.rygCount.yellow}`,
            className: "rygToggle-y" + (state.rygFocus == "YELLOW" ? " y-focus" : ""),
            onclick: () => {
              actions.updateState({
                rygFocus: "YELLOW",
              }),
                this.fetchTasks(state.selectedType, "YELLOW");
            },
          },
          state.rygCount.yellow > 100 ? "99+" : state.rygCount.yellow,
        ),
        h(
          "div",
          {
            title: `${state.rygCount.green}`,
            className: "rygToggle-g" + (state.rygFocus == "GREEN" ? " g-focus" : ""),
            onclick: () => {
              actions.updateState({
                rygFocus: "GREEN",
              }),
                this.fetchTasks(state.selectedType, "GREEN");
            },
          },
          state.rygCount.green > 100 ? "99+" : state.rygCount.green,
        ),
        h(
          "div",
          {
            title: `${state.rygCount.blue}`,
            className: "rygToggle-b" + (state.rygFocus == "BLUE" ? " b-focus" : ""),
            onclick: () => {
              actions.updateState({
                rygFocus: "BLUE",
              }),
                this.fetchTasks(state.selectedType, "BLUE");
            },
          },
          state.rygCount.blue > 100 ? "99+" : state.rygCount.blue,
        ),
      ],
    );

    return super.render("notificationCenter", [
      h(
        "div",
        {
          className: "osjs-panel-notificationCenter",
          onclick: (event) => this.showNotificationPanel(event, actions),
          oncreate: () => this.fetchTasks("assignments"),
        },
        [
          h("i", {
            className: "fa-solid fa-clipboard-check",
            title: "Priorities",
          }),
          h("div", {
            className: "notificationCenter-badge",
            style:
              state.totalCount == 0
                ? {
                    opacity: 0,
                  }
                : null,
            innerHTML: state.totalCount,
          }),
        ],
      ),
      h(
        "div",
        {
          className: "notificationCenter-panel-container",
        },
        h(
          "div",
          {
            onclick: (event) => event.stopPropagation(),
            className: "notificationCenter-panel",
          },
          h(
            "div",
            {
              className: "dropdown-container",
            },
            [
              h(
                "div",
                {
                  className: "dropdown-info",
                },
                [
                  h(
                    "div",
                    {
                      onclick: (event) => {
                        event.stopPropagation();
                        actions.updateState({
                          showDropdown: !state.showDropdown,
                        });
                      },
                      className: "dropdown-name",
                    },
                    state.selectedType == "assignments" ? "My Priorities" : state.selectedType == "followups" ? "My Follow Up" : "My Snoozed Items",
                  ),
                  h("i", {
                    onclick: (event) => {
                      event.stopPropagation();
                      actions.updateState({
                        showDropdown: !state.showDropdown,
                      });
                    },
                    style: { cursor: "pointer" },
                    className: state.showDropdown ? "fa-duotone fa-circle-chevron-up" : "fa-duotone fa-circle-chevron-down",
                  }),
                ],
              ),
              h(
                "div",
                {
                  className: "ryg-component",
                },
                rygComponent,
              ),
            ],
            state.showDropdown
              ? h(
                  "div",
                  {
                    className: "dropdown-list-container",
                  },
                  [
                    h(
                      "div",
                      {
                        className: "dropdown-list",
                      },
                      [
                        dropdownOptions.map((each, index) => {
                          return (
                            each.value !== state.selectedType &&
                            h(
                              "div",
                              {
                                className: "each-option",
                                onclick: (event) => {
                                  actions.updateState({
                                    selectedType: each.value,
                                    taskList: [],
                                    loading: true,
                                    showDropdown: false,
                                  });
                                  this.fetchTasks(each.value);
                                },
                              },
                              [
                                h(
                                  "div",
                                  {
                                    className: "each-option-icon",
                                  },
                                  [
                                    h("i", {
                                      style: {
                                        color: "rgba(47, 69, 92, 0.5)",
                                        fontSize: "10px",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                      },
                                      className: each.icon,
                                    }),
                                  ],
                                ),
                                h(
                                  "div",
                                  {
                                    className: "each-option-label",
                                  },
                                  each.label,
                                ),
                              ],
                            )
                          );
                        }),
                      ],
                    ),
                  ],
                )
              : null,
          ),
          state.taskList.length > 0
            ? h(
                "div",
                { className: "task-list" },
                state.taskList.map((task, index) =>
                  h(
                    "div",
                    {
                      className: "task-item",
                    },
                    [
                      h(
                        "div",
                        {
                          className: "taskname-container",
                          onclick: () => {
                            slide[0]?.classList.remove("panel-active");
                            this.gotoTaskViewPage(task);
                          },
                        },
                        [
                          h("div", {
                            className: "ryg-status-bar",
                            style: {
                              backgroundColor:
                                task.rygStatus === "RED"
                                  ? "#F93F3F"
                                  : task.rygStatus === "YELLOW"
                                  ? "#FFCD43"
                                  : task.rygStatus === "GREEN"
                                  ? "#34A853"
                                  : "#416080",
                            },
                          }),
                          h(
                            "div",
                            {
                              className: "title",
                              style: state.selectedType === "snoozed" ? { paddingLeft: "6px" } : { padding: "7px 0px 0px 6px" },
                            },
                            task.name ?? "NA",
                          ),
                          h("div", { className: "app-name" }, task.app_title ?? "NA"),
                          state.selectedType === "snoozed"
                            ? h("div", { className: "snoozed-till-date" }, task.snoozed_till ? "Snoozed Until" + " " + task.snoozed_till : "NA")
                            : null,
                        ],
                      ),
                    ],
                    taskActions?.map((eachAction) =>
                      h(
                        "div",
                        {
                          className: "task-item-actions",
                          onclick: () => {
                            (eachAction.name === "snooze" && task.rygStatus !== "GREEN") ||
                            (eachAction.name === "snooze" && new Date(task.end_date).getTime() < new Date().getTime())
                              ? null
                              : actions.onTaskActionClick({
                                  action: eachAction.name,
                                  task: task,
                                  actions: actions,
                                });
                          },
                        },
                        [
                          h("i", {
                            style: {
                              color:
                                (eachAction.name === "snooze" && task.rygStatus !== "GREEN") ||
                                (eachAction.name === "snooze" && new Date(task.end_date).getTime() < new Date().getTime())
                                  ? "#D9D9D9"
                                  : "#0057FF",
                              cursor:
                                (eachAction.name === "snooze" && task.rygStatus !== "GREEN") ||
                                (eachAction.name === "snooze" && new Date(task.end_date).getTime() < new Date().getTime())
                                  ? "not-allowed"
                                  : "pointer",
                            },
                            className: eachAction.icon,
                            name: eachAction.name,
                          }),
                        ],
                      ),
                    ),
                  ),
                ),
              )
            : h("div", {
                className: "tasks-completed",
                innerHTML: state.loading ? "" : "Nothing here. </br> You are all done for the day!",
              }),
          h(
            "button",
            {
              className: "view-all-button",
              onclick: () => {
                slide[0]?.classList.remove("panel-active");
                actions.launchNotificationApp(state, actions);
              },
            },
            "View All",
          ),
          state.isCommentsModalOpen
            ? h("div", {
                className: "modal-container",
                style: state.commentsModalFlag === "comments" ? { width: "650px" } : { width: "450px" },
                id: "comments-modal",
                oncreate: setTimeout(() => {
                  this.loadModalComponent(state, actions, this.helper, "comments");
                }, 1000),
              })
            : null,
          state.isSnoozeModalOpen
            ? h("div", {
                className: "modal-container",
                style: state.snoozeModalFlag === "extend" ? { width: "550px" } : { width: "450px" },
                id: "snooze-modal",
                oncreate: setTimeout(() => {
                  this.loadModalComponent(state, actions, this.helper, "snooze");
                }, 1000),
              })
            : null,
        ),
      ),
    ]);
  }
}
