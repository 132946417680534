import { h } from "hyperapp";
import PanelItem from "../panel-item";
export default class GlobalSearch extends PanelItem {
  init() {
    if (this.inited) {
      return;
    }

    this.helper = this.core.make("oxzion/restClient");
    this.GlobalSearchActions = super.init(
      {
        selectedFilter: "apps",
        recentSearchedData: [],
        showEmptySearchErrMsg: false,
        showSpecialCharErrMsg: false,
        showPanel: false,
      },
      {
        updateState: this.updateState,
        saveRecentSearchHistory: this.saveRecentSearchHistory,
        fetchRecentSearchHistory: this.fetchRecentSearchHistory,
        focusGlobalSearchPanel: this.focusGlobalSearchPanel,
        hideGlobalSearchPanel: this.hideGlobalSearchPanel,
        launchGlobalSearchApp: this.launchGlobalSearchApp,
      },
    );

    this.filterOptions = [
      {
        label: "Apps",
        value: "apps",
      },
      {
        label: "Users",
        value: "user",
      },
      {
        label: "Attachments",
        value: "attachment",
      },
      {
        label: "Comments",
        value: "comment",
      },
    ];

    this.spclCharRegex = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
  }

  saveRecentSearchHistory = (searchedValue) => {
    const payload = {
      search_history: searchedValue,
    };
    this.helper
      .request("v1", `/user/updateusersearchitems`, payload, "post")
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  fetchRecentSearchHistory = () => {
    this.helper
      .request("v1", `/user/me`, {}, "get")
      .then((response) => {
        if (response?.data?.search_history) {
          let strArray = [];
          strArray = response?.data?.search_history?.split(",");
          this.GlobalSearchActions.updateState({
            recentSearchedData: strArray,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  focusGlobalSearchPanel() {
    let allOsjsPanelItems = document.getElementsByClassName("osjs-panel-item");
    var arr = [].slice.call(allOsjsPanelItems);
    let notificationElement = arr?.filter((item) => item?.dataset?.name === "notificationCenter");
    let globalSearchElement = arr?.filter((item) => item?.dataset?.name === "globalSearch");
    if (notificationElement?.length > 0) notificationElement[0].style.zIndex = 1;
    if (globalSearchElement?.length > 0) globalSearchElement[0].style.zIndex = 2;
  }

  hideGlobalSearchPanel(actions) {
    const searchBarElement = document.getElementsByClassName("global-search-bar");
    const searchIconElement = document.getElementsByClassName("search-icon");
    searchBarElement[0]?.classList.remove("panel-active");
    searchIconElement[0]?.classList.remove("search-icon-inactive");
    actions.updateState({
      showPanel: false,
    });
  }

  launchGlobalSearchApp = (state) => {
    let searchedValue = document.getElementById("search-input")?.value ?? "";
    if (searchedValue === "")
      this.GlobalSearchActions.updateState({
        showEmptySearchErrMsg: true,
        showSpecialCharErrMsg: false,
      });
    else if (this.spclCharRegex.test(searchedValue))
      this.GlobalSearchActions.updateState({
        showSpecialCharErrMsg: true,
      });
    else {
      this.core.run("GlobalSearch", {
        searchedValue: searchedValue,
        selectedFilter: state.selectedFilter
      });
      this.GlobalSearchActions.updateState({
        showPanel: false,
      });
      this.GlobalSearchActions.saveRecentSearchHistory(searchedValue);
    }
  };

  updateState = (data) => data;

  render(state, actions) {
    const searchBarElement = document.getElementsByClassName("global-search-bar");
    const searchIconElement = document.getElementsByClassName("search-icon");

    if (searchBarElement[0]?.classList.contains("panel-active")) {
      document.body.onclick = function (e) {
        actions.hideGlobalSearchPanel(actions);
      };
    }
        
    return super.render("globalSearch", [
      h(
        "div",
        {
          className: "global-search-container",
        },
        [
          h(
            "div",
            {
              className: "global-search-bar",
            },
            [
              h("i", {
                className: "fa-solid fa-magnifying-glass input-search-icon",
              }),
              h("input", {
                type: "text",
                id: "search-input",
                placeholder: "Search",
                autocomplete: "off",
                onclick: (event) => event.stopPropagation(),
                onkeypress: (event) => {
                  event.stopPropagation();
                  if (event.key == "Enter") actions.launchGlobalSearchApp(state);
                },
                onfocus: (event) => {
                  event.stopPropagation();
                  actions.updateState({
                    showEmptySearchErrMsg: false,
                    showPanel: true,
                    showSpecialCharErrMsg: false,
                  });
                  actions.focusGlobalSearchPanel();
                },
                onchange: (event) => {
                  actions.updateState({
                    showEmptySearchErrMsg: false,
                  });
                },
                className: "search-bar",
              }),
              h("i", {
                className: "fa-solid fa-circle-xmark input-clear-icon",
                onclick: (e) => {
                  e.stopPropagation();
                  actions.hideGlobalSearchPanel(actions);
                  document.getElementById("search-input").value = "";
                },
              }),
            ],
          ),
        ],
        [
          h("i", {
            className: "search-icon fa-solid fa-magnifying-glass osjs-panel-item--clickable",
            title: "Global Search",
            onclick: (event) => {
              event.stopPropagation();
              searchBarElement[0]?.classList.add("panel-active");
              searchIconElement[0]?.classList.add("search-icon-inactive");
              actions.fetchRecentSearchHistory();
              document.getElementById("search-input").focus();
              document.getElementById("search-input").value = "";
              actions.updateState({
                selectedFilter: "apps",
              });
            },
          }),
        ],
      ),
      state.showPanel &&
        h(
          "div",
          {
            className: "global-search-container",
          },
          h(
            "div",
            {
              onclick: (event) => event.stopPropagation(),
              className: "global-search-panel",
            },
            [
              h(
                "div",
                {
                  className: "search-filters",
                },
                [
                  state.showSpecialCharErrMsg &&
                    h(
                      "div",
                      {
                        className: "error-msg",
                      },
                      "The use of special characters is not allowed!",
                    ),
                  state.showEmptySearchErrMsg &&
                    h(
                      "div",
                      {
                        className: "error-msg",
                      },
                      "Please enter something to search!",
                    ),
                  h(
                    "div",
                    {
                      className: "search-label",
                    },
                    "What are you searching for?",
                  ),
                  h(
                    "div",
                    {
                      className: "filter-option-container",
                    },
                    this.filterOptions.map((each) =>
                      h(
                        "div",
                        {
                          className: "filter-option" + (each.value === state.selectedFilter ? " focus" : ""),
                          onclick: () => {
                            let searchedValue = document.getElementById("search-input")?.value ?? "";
                            if (searchedValue === "")
                              actions.updateState({
                                showEmptySearchErrMsg: true,
                                showSpecialCharErrMsg: false,
                              });
                            else if (this.spclCharRegex.test(searchedValue))
                              actions.updateState({
                                showSpecialCharErrMsg: true,
                              });
                            else {
                              this.core.run("GlobalSearch", {
                                selectedFilter: each.value,
                                searchedValue: searchedValue,
                              });
                              actions.updateState({
                                selectedFilter: each.value,
                                showPanel: false,
                              });
                              actions.saveRecentSearchHistory(searchedValue);
                            }
                          },
                        },
                        [
                          h(
                            "div",
                            {
                              className: "option-name",
                            },
                            each.label,
                          ),
                        ],
                      ),
                    ),
                  ),
                  state.recentSearchedData?.length !== 0 &&
                    state.recentSearchedData[0] !== "" &&
                    h(
                      "div",
                      {
                        className: "recent-searched-container",
                      },
                      h(
                        "div",
                        {
                          className: "search-label margin-top",
                        },
                        "Recent",
                      ),
                      h(
                        "div",
                        {
                          className: "recent-searched",
                        },
                        state.recentSearchedData?.map((each) => {
                          let searchedValue = document.getElementById("search-input")?.value ?? "";
                          return h(
                            "div",
                            {
                              className: "data-name" + (each == searchedValue ? " label-focus" : ""),
                              onclick: () => {
                                document.getElementById("search-input").focus();
                                document.getElementById("search-input").value = each;
                              },
                            },
                            each,
                          );
                        }),
                      ),
                    ),
                ],
              ),
            ],
          ),
        ),
    ]);
  }
}
